@import '../fontface/fontface';
@import '../styles/variables';

body {
  font-family: var(--font-family-primary), var(--font-family-secondary);
}
#root > .rst__tree {
  height: 0; // для кейса с лишней прокруткой на страницах
}
.MuiInputLabel-formControl { // некоторые label некрасиво переносятся
  white-space: nowrap;
}
.queryFieldDivider {
  flex: 0 0 auto;
  padding: 0 12px;
}
.MuiInputLabel-root.MuiInputLabel-shrink { // чтобы у текстовых полей с длинными названиями выводились первые 2 строки
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  max-width: 100%;
  max-height: 29px;
  top: -3px;
  .MuiAppBar-root & {
    top: 2px;
  }
}
html .MuiInputBase-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.65);
}

*::-webkit-scrollbar {
  width: 8px;
  /* ширина scrollbar */
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
  /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  /* цвет плашки */
  border-radius: 20px;
  /* закругления плашки */
  border: 3px solid rgba(0, 0, 0, 0);
  /* padding вокруг плашки */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0f5;
  transition: .2s;
}

.normalize-maplibregl-popup {
  & .maplibregl-popup-tip {
    display: none;
  }
  & .maplibregl-popup-content {
    padding: 0;
    & button.maplibregl-popup-close-button {
      display: none;
    }
  }
}

.superset-dashboard {
  display: flex;
  flex-direction: column;
}

@media print {
  .print-hide {
    display: none;
  }

  .print-width {
    max-width: "280mm"
  }
}