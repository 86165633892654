@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular.woff2') format('woff2'),
         url('./Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold.woff2') format('woff2'),
         url('./Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('./SFUIDisplay-Medium.woff2') format('woff2'),
         url('./SFUIDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF UI Display';
    src: url('./SFUIDisplay-Bold.woff2') format('woff2'),
         url('./SFUIDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
