.rst__rowWrapper {
  padding: 4px 4px 4px 0;
  height: 100%;
  box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 4px 0 4px 4px;
}

.group .rst__rowContents {
  background-color: #e0f0ff;
}

.col-internal .rst__rowContents {
  background-color: #ebebeb;
}

.col-deleted .rst__rowContents {
  background-color: #ebb0b0;
}

.provider-selector-item .rst__rowContents {
  background-color: #e0f0ff;
  width: 400px;
}
.provider-selector-item .rst__rowLabel {
  width: 100%;
}

.geo-item-disabled .rst__rowContents {
  background-color: #e0e0e0;
}

.geo-item-selected .rst__rowContents {
  background-color: #e0f0e0;
}

.geo-item-selectable .rst__rowContents {
  background-color: #e0e0f0;
}

.column-selector-leaf .rst__rowContents {
  background-color: #e0f0ff;
}
.column-selector-node .rst__rowContents {
  background-color: #e0e0e0;
}
.column-selector-selected .rst__rowContents {
  background-color: #e0f0e0;
}

.column-selector-container .rst__virtualScrollOverride {
  background-color: #f2f2f2;
  border-color: #0000ff;
  border-style: solid;
  border-width: 1px;
}

/* leaflet */
.leaflet-control-attribution {
  display: none;
}

.colselector-normal .rst__rowContents {
  background-color: #f0f0f8;
}
.colselector-selected .rst__rowContents {
  background-color: #e0f0e0;
}
.colselector-disabled .rst__rowContents {
  background-color: #d0d0d0;
}

.superset-dashboard {
  min-height: 1500px;
  overflow-y: visible;
  margin-top: 48px;
}

.superset-dashboard iframe {
  height: calc(100vh - 80px);
}
